.telefonica {
  padding: rem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  overflow: hidden;
  background: $color__white;
  position: relative;
  width: 100%;
  img {    
    height: calc(#{$height__telefonica} - #{rem(40)});
    display: block!important;    
  }
  .app--scroll & {
    position: sticky;
    height: $height__telefonica;
    bottom: 0;
    width: 100%;
    z-index: $index__telefonica--fixed;

    /* Allows you to scroll below the viewport; default value is visible */
    overflow-y: scroll;

    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
  }
  &__logo--header {
    filter: brightness(0) invert(1);    
  }
}
