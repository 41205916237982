.page {
  display: grid;
  &--select-language {
    grid-template-rows: 80% auto;
  }
  &--seleccion-sala {
    display: grid;
    height: 100%;
    grid-template-rows: min-content auto;
    .subtitle {
      padding-top: flex-height(76);
      margin-bottom: flex-height(70);
      max-width: none;
      align-self: start;
    }
  }
  @include respond-to(landscape) {
    display: block;
  }
}
