.modal {
  position: fixed;
  z-index: $index__modal;
  width: 90%;
  height: 90%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: $color__white;
  box-shadow: 0 0 20px rgba($color: $color__black, $alpha: 0.4);
  overflow: auto;
  padding: 5% flex-height(74);
  &__logo {
    max-width: 390px;
    margin: 0 auto flex-height(99);
  }
  &__text {
    margin-bottom: 10%;
    h2 {
      margin-bottom: flex-height(24);
      @include lead;
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: flex-height(24);
      img {
        width: flex-vwidth(80);
        margin-right: flex-vwidth(23);
        max-width: 126px;
        flex-shrink: 0;
      }
    }
  }
  @include respond-to(landscape) {
    &__logo {
      margin: 0 auto rem(99);
    }
    padding: 5% rem(74);
    li {
      margin-bottom: rem(24);
    }
  }
}
