.error {
  position: relative;
  justify-content: center;
  padding: rem(50);
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  @include body-final;
  &__image {
    width: rem(115);
  }
  &__text {
    margin: rem(50) 0;
  }
}
