.color-band {
  display: flex;
  height: 20px;
  justify-content: space-evenly;
  align-items: stretch;
  & > div {
    height: 100%;
    width: 100%;
  }
  &__yellow {
    background-color: $color__yellow;
  }
  &__green {
    background-color: $color__green;
  }
  &__purple {
    background-color: $color__purple;
  }
  &__brown {
    background-color: $color__brown;
  }
  &__red {
    background-color: $color__red;
  }
  transition: height 400ms $ease__circout;
  &--sala {
    height: 10px;
  }
}
