.page--sala {
  position: relative;
  padding-top: flex-height(77);
  @include respond-to(landscape) {
    padding-top: rem(77);
  }
}
.sala {
  &__counter {
    @extend .seleccion-sala__button;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-50%) scale(0.6);
    position: absolute;
    border: 5px solid $color__white;
    box-sizing: content-box;
  }
  &__content {
    p {
      margin-bottom: 1em;
    }
    a {
      text-decoration: underline;
      color: $color__red;
    }
    width: flex-width(585);
    margin: 0 auto 20px;
  }
  &__navigation {
    justify-content: space-between;
    width: flex-width(585);
    flex-direction: row;
    position: relative;
    z-index: $index__navegacion;
    margin: 0 auto rem(20);
    align-items: center;
    .navigation__button {
      white-space: nowrap;
      display: flex;
      align-items: center;
      width: max-content;
      padding: rem(5) rem(8);
      img {
        @include responsive-height-image;
        max-height: 20px;
      }
      &:first-child {
        margin-right: rem(5);
        img {
          margin-right: rem(5);
        }
      }
      &:last-child {
        img {
          margin-left: rem(5);
        }
      }
    }
    &--centered {
      justify-content: center;
      margin-right: 0;
      width: 100%;
      .navigation__button {
        &:first-child {
        }
      }
    }
  }
  &__sticky {
    position: sticky;
    bottom: 0;
  }
  &__imagen {
    width: flex-width(630);
    margin: rem(0) auto rem(30);
    max-width: 1024px;
  }
  @include respond-to(small) {
    &__navigation {
      img {
        max-height: 30px;
      }
      .navigation__button {
        padding: rem(10) rem(20);
      }
    }
  }
}
