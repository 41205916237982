.pregunta {
  width: flex-width(592);
  max-width: 592px;
  margin: 0 auto;
  margin-bottom: flex-height(62);
  &:after {
    position: relative;
    @extend .subtitle:after;
    justify-self: center;
    max-width: 65%;
  }
  &__title {
    @include body-final;
    text-align: center;
    margin-bottom: flex-height(26);
  }
  &__values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: flex-height(57);
  }
  &__value {
    transition: transform 400ms $ease__circout;
    &--selected {
      transform: scale(1.5);
    }
  }
  &__button {
    transition: transform 400ms $ease__circout;
    &--selected {
      transform: scale(1.2);
    }
  }
  &__image {
    width: flex-vwidth(62);
    max-width: 50px;
  }
  &__error {
    color: $color__red;
    font-size: rem(14);
    font-style: italic;
    margin-bottom: rem(10);
  }

  @include respond-to(landscape) {
    margin-bottom: rem(62);
    &__title {
      margin-bottom: rem(26);
    }
    &__values {
      padding-bottom: rem(57);
    }
  }
}
