.page {
  &--custionario {
    padding-top: flex-height(80);
    text-align: center;
  }
  @include respond-to(landscape) {
    &--custionario {
      padding-top: rem(80);
    }
  }
}
