.page--instrucciones {
  padding-top: flex-height(74);
  margin: 0 auto rem(50);
  width: flex-width(580);
  ol {
    padding: 0;
    list-style: none;
    counter-reset: my-awesome-counter;
    li {
      margin-bottom: flex-height(68);
      counter-increment: my-awesome-counter;
      display: flex;
      align-items: center;
      &::before {
        flex-shrink: 0;
        margin-right: flex-width(29, 580);
        content: counter(my-awesome-counter) "";
        width: flex-vwidth(104);
        height: flex-vwidth(104);
        max-width: 100px;
        max-height: 100px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
        color: $color__white;
        @include title;
        background: $color__red;
      }
    }
  }
}
