// Breakpoints.
$responsive: true;
$breakpoints: (
  "full": (
    min-width: 1501px,
  ),
  "desktop": (
    min-width: 1201px,
  ),
  "wide": (
    min-width: 1025px,
  ),
  "viewport": (
    min-width: 981px,
  ),
  "tall": (
    min-width: 769px,
  ),
  "small": (
    min-width: 540px,
  ),
  "mini": (
    min-width: 375px,
  ),
  "landscape": (
    orientation: landscape,
  ),
);
// Mixin para responsive uso @include respond-to(full) {....}
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if $responsive {
    @if map-has-key($breakpoints, $breakpoint) {
      // Prints a media query based on the value
      @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    }
    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
  }
}
