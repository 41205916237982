@import "color-band";
@import "header/header";
@import "footer/footer";
@import "modal";
@import "subtitle";
@import "language-selector";
@import "telefonica";
@import "loading";
@import "error";
@import "audioplayer";
@import "pregunta";

.remove-scroll {
  overflow: hidden;
}
