.app {
  &__container {
    display: grid;
    width: 100%;
    grid-template-rows:
      min-content $height__header calc(80% - #{$height__telefonica} - 20px)
      $height__telefonica;
    @include respond-to(landscape) {
      display: block;
      overflow: auto;
      height: auto;
    }
    &.app--scroll {
      grid-template-rows: min-content $height__header min-content;
    }
    &.app--salas {
      display: block;
    }

    &.app--header-small {
      grid-template-rows:
        min-content $height__header-small calc(
          85% - #{$height__telefonica} - 20px
        )
        $height__telefonica;
    }
  }
}
