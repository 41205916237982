$color__yellow: #daaa00;
$color__mustard: #dbab00;
$color__green: #949300;
$color__purple: #890c58;
$color__brown: #7c2529;
$color__grey: #4f758b;
$color__black: #000000;
$color__white: #fff;
$color__red: #c9102e;

$color__background: $color__white;
$color__body-text: $color__black;
