.loading {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  padding: rem(60) rem(20);
  @include body-final;
  &__text {
    margin-top: rem(24);
  }
}
