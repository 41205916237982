.page {
  &--social-share {
    width: flex-width(592);
    max-width: 592px;
    margin: 0 auto;
    margin-bottom: flex-height(62);
    text-align: center;
    padding-top: flex-height(80);
    display: grid;
  }
  @include respond-to(landscape) {
    display: block;
    &--social-share {
      padding-top: rem(80);
    }
  }
}
.social-share {
  &__image {
    position: relative;
    padding-bottom: rem(40);
    margin-bottom: flex-height(40);
  }
  &__spotify {
    position: absolute;
    bottom: rem(-20);
    right: flex-width(-90, 592);
    width: flex-height(122);
  }
  &__cover {
    width: flex-height(347);
    margin: 0 auto;
    a {
      position: relative;
    }
  }
  &__title {
    a {
      display: inline-block;
      color: $color__red;
    }
  }
  &__items {
    display: flex;
    justify-content: center;
  }
  &__button {
    width: flex-width(50, 592);
    min-width: 32px;
    svg {
      max-width: 100%;
      height: auto;
    }
    &:not(:last-child) {
      margin-right: flex-width(37, 592);
    }
  }

  &__goodbye {
    margin-top: flex-height(57);
    @include goodbye;
    strong {
      @include goodbye-bold;
      color: $color__red;
    }
    margin-bottom: flex-height(57);
  }

  &__title {
    margin-bottom: flex-height(20);
  }
  @include respond-to(landscape) {
    &__goodbye {
      margin-top: rem(57);
    }
    &__title {
      margin-bottom: rem(39);
    }
  }
}
