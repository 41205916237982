.audioplayer {
  audio {
    display: block;
    width: 100%;
  }
  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: 100%;
    height: 100%;
    width: 100%;
    left: 0;
    background: linear-gradient(
      to top,
      $color__white 0%,
      rgba($color__white, 0)
    );
  }
  padding: rem(20) rem(45);
  position: sticky;
  bottom: 0;
  width: 100%;
  background: $color__grey;
  color: $color__white;
  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__subtitle {
    @include subtitle;
    margin-bottom: rem(10);
  }
  &__time {
    @include time;
  }
  &__slider {
    flex-grow: 1;
    width: 100%;
    appearance: none;
    height: 6px;
    outline: none;
    border: 0;
    background: $color__white;
    margin-left: rem(35);
    &:focus {
      outline: 0;
    }
    &::-webkit-slider-thumb {
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $color__white;
      &:hover,
      &:active {
        transform: scale(1.2);
      }
    }
  }
  &__playpause {
    flex-shrink: 0;
  }
  @include respond-to(tall) {
    padding: rem(20) rem(25);
    margin-right: rem(20);
  }
}
