// All elements border box
html {
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Heigt adjusted to viewport
/*
Does this really work? […] I’ve had no problems with any of the tests I’ve run and I’m using this method in production right now. But I did receive a number of responses to my tweet pointing to other possible problems with using this (the effects of rotating devices, Chrome not completely ignoring the property, etc.)
*/
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  color: $color__body-text;
  background-color: $color__background;
  @include body;
}

html {
  height: -webkit-fill-available;
}

a {
  color: inherit;
  text-decoration: none;
  display: block;
}

input,
select {
  font-family: inherit;
  font-size: inherit;
  border-radius: 0px;
  border-style: solid;
}

img,
video {
  max-width: 100%;
  height: auto;
}

button {
  border: 0;
}

h1,
h2,
h3,
p {
  margin-bottom: em(0);
}

p {
  margin-bottom: 1em;
}

canvas {
  display: block;
}

*:focus {
  outline: 0px dashed;
}

strong,
b {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

textarea:focus,
input:focus {
  outline: 2px dashed;
}

button {
  background: transparent;
  border: 0;
}
