.telefonica__logo--header img{
  display: block!important;
}
.header {
  background: $color__grey;
  align-content: center;
  transform-origin: top center;
  position: relative;
  overflow: hidden;
  padding: rem(10);
  &__container,
  &__logo {
    height: 100%;
  }
  .app--scroll & {
    position: sticky;
    top: 0;
    z-index: $index__telefonica--fixed;
  }
  &__link {
    transition: height 400ms $ease__circout;
    height: 100%;
    text-align: center;
    @include responsive-height-image;
    &--small {
      margin-top: flex-height(10);
      height: 85%;
      padding: 0 rem(20);
    }
  }
  &__bgimage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color__black;
    img {
      object-fit: cover;
      opacity: 0.7;
      width: 100%;
      height: 100%;
    }
    animation: fadein 800ms $ease__circout;
  }
  &__container {
    position: relative;
    z-index: $index__header-content;
  }
  &__image {
    transition: padding-bottom 400ms $ease__circout;
  }
  &__image--horizontal {
    padding-bottom: flex-height(30);
  }
  &__image--shadow {
    filter: drop-shadow(0 0 15px rgba($color__black, 0.5));
    padding-bottom: flex-height(40);
  }
  // cambios especificos para salas
  .app--salas & {
    height: flex-height(443, 1874);
    &__logo {
      height: 55%;
    }
    &__link {
      @include responsive-height-image;
      &--small {
        img {
          padding-bottom: rem(20);
        }
        height: 100%;
      }
    }
    .telefonica__logo--header {
      display: none;
      text-align: right;
      float: right;
      height: flex-height(118, 1874);
      margin-right: flex-width(25);
      @include responsive-height-image;
    }
  }
  // Los altos pasan a ser en rem
  @include respond-to(landscape) {
    padding: rem(32) flex-width(22) rem(32);
    &__link {
      height: rem(203);
      &--small {
        height: rem(173);
      }
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
