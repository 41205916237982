.subtitle {
  @include title;
  position: relative;
  padding-bottom: 3.2%;
  margin-bottom: 8.5%;
  display: flex;
  justify-content: center;
  max-width: 430px;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    max-width: 352px;
    margin: 0 auto;
    bottom: 0;
    display: block;
    height: 6px;
    background-color: $color__red;
    transform-origin: center center;
  }
  &__full-center {
    display: flex;
    justify-content: center;
  }
}
