.language {
  background: $color__grey;
  overflow: hidden;
  display: grid;
  height: 100%;
  &__text {
    color: $color__white;
    text-align: center;
    padding: rem(10);
    padding-bottom: 0;
    max-width: 750px;
    margin: 0 auto;
  }
  &__paragraph:not(:last-child) {
    margin-bottom: flex-height(20);
  }
  &__selector {
    margin: flex-height(22) auto flex-height(26);
    display: flex;
    justify-content: space-between;
    width: 320px;
  }

  &__link {
    opacity: 0.7;
    img {
      transition: all 200ms $ease__circout;
      border: 2px solid transparent;
      padding: rem(3);
      box-sizing: content-box;
    }
    &--selected {
      opacity: 1;
      img {
        border: 2px solid $color__white;
      }
    }
  }

  &__option {
    flex-shrink: 1;
  }
  &__image {
    width: flex-width(94, 520);
    min-width: 60px;
    // height: 54px;
  }
  &__navigation {
    width: flex-width(320);
    min-width: 300px;
    align-self: center;
    justify-self: center;
    margin-bottom: rem(15);
  }
  @include respond-to(landscape) {
    &__text {
      padding: rem(22) rem(32) rem(0);
    }
    &__paragraph:not(:last-child) {
      margin-bottom: rem(40);
    }
    &__selector {
      margin: rem(22) auto rem(26);
    }
    &__navigation {
      padding-bottom: rem(35);
    }
  }
}

.footer {
  position: relative;
  width: 100%;
  pointer-events: none;
  background: $color__white;
  @include responsive-height-image;
  justify-self: center;
  overflow: hidden;
  text-align: center;
  img {
    height: auto;
    position: relative;
    z-index: 2;
  }
  &__color-band {
    position: absolute;
    bottom: 0;
    background: $color__red;
    width: 100%;
    height: 20px;
  }
  @include respond-to(landscape) {
    position: relative;
    display: block;
    &__image {
      img {
        height: rem(352);
      }
    }
    &__navigation {
      padding-bottom: rem(20);
    }
    &__color-band {
      height: 40px;
    }
  }
}
