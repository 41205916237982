@font-face {
  font-family: "social-icons";
  src: url("../../fonts/social-icons.eot?22706202");
  src: url("../../fonts/social-icons.eot?22706202#iefix")
      format("embedded-opentype"),
    url("../../fonts/social-icons.woff2?22706202") format("woff2"),
    url("../../fonts/social-icons.woff?22706202") format("woff"),
    url("../../fonts/social-icons.ttf?22706202") format("truetype"),
    url("../../fonts/social-icons.svg?22706202#social-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'social-icons';
      src: url('../../fonts/social-icons.svg?22706202#social-icons') format('svg');
    }
  }
  */

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "social-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-spotify:before {
  content: "\f1bc";
} /* '' */
.icon-whatsapp:before {
  content: "\f232";
} /* '' */
.icon-twitter-circled:before {
  content: "\f30a";
} /* '' */
.icon-facebook-circled:before {
  content: "\f30d";
} /* '' */
