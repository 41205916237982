.navigation {
  text-align: center;
  display: flex;
  justify-content: center;
  &__button {
    background: $color__mustard;
    @include button;
    padding: rem(12) rem(12);
    width: 100%;
    text-transform: uppercase;
    max-width: 340px;
    &:active {
      transform: scale(0.98);
    }
  }
}
