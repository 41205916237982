.language-selector {
  color: $color__black;
  background: rgba($color__white, 0);
  padding: rem(10);
  padding-bottom: rem(0);
  transform-origin: top right;
  display: flex;
  flex-direction: column;
  transition: background-color 400ms $ease__circout;
  &__item {
    margin-bottom: rem(10);
  }
  &__button {
    display: flex;
    text-transform: uppercase;
    align-items: center;
    @include languaje-option;
    color: $color__black;
    justify-content: flex-end;
    // width: rem(108);
    transition: color 400ms $ease__circout;
  }
  &__image {
    margin-left: rem(13);
    width: 45px;
  }
  &--modal,
  &--header {
    position: absolute;
    top: rem(8);
    right: rem(8);
    color: $color__white;
    z-index: $index__language-selector;
    .language-selector__button {
      color: $color__black;
    }
  }
  &--header {
    top: rem(5);
    .language-selector__button {
      color: $color__white;
    }
  }
  &__open {
    background: rgba($color__white, 0.7);
    .language-selector__button {
      color: $color__black;
    }
  }
}
