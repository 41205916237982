@mixin title {
  font-family: Gotham;
  font-size: 2.906rem;
  @include rem-fluid-type(1.5rem, 2.906rem);
  font-weight: 900;
  text-align: center;
}

@mixin counter {
  font-family: Gotham;
  font-size: 2.906rem;
  @include rem-fluid-type(1.2rem, 2.5rem);
  font-weight: 900;
  text-align: center;
}

@mixin lead {
  font-family: Gotham;
  font-size: rem(29);
  line-height: 1.2;
  @include rem-fluid-type(0.875rem, 1.8125rem);
  font-weight: 500;
  text-align: center;
}

@mixin body-final {
  font-family: Gotham;
  font-size: 1.813rem;
  text-align: center;
  @include rem-fluid-type(0.875rem, 1.8125rem);
}

@mixin body {
  font-family: Gotham;
  font-size: 1.813rem;
  line-height: 1.4;
  @include rem-fluid-type(0.875rem, 1.8125rem);
  text-align: left;
}

@mixin subtitle {
  font-family: Gotham;
  font-size: 1.5rem;
  @include rem-fluid-type((14 / 16) * 1rem, 1.5rem);
  font-weight: 900;
  text-align: center;
}

@mixin powered {
  font-family: Gotham;
  font-size: 1.469rem;
  text-align: center;
}

@mixin button {
  font-family: Gotham;
  font-size: 1.438rem;
  font-weight: bold;
  line-height: 1.61;
  @include rem-fluid-type((14 / 16) * 1rem, (23 / 16) * 1rem);
  text-align: center;
}

@mixin language {
  font-family: Gotham;
  font-size: rem(35.5);
}

@mixin time {
  font-family: Gotham;
  font-size: rem(24);
  @include rem-fluid-type((12 / 16) * 1rem, (24 / 16) * 1rem);
  font-weight: 500;
  text-align: center;
}

@mixin languaje-option {
  font-size: rem(35.5);
  @include rem-fluid-type((20 / 16) * 1rem, (24 / 16) * 1rem);
  text-align: right;
}

@mixin goodbye {
  font-family: Austin;
  font-size: rem(48);
  @include rem-fluid-type((24 / 16) * 1rem, (48 / 16) * 1rem);
  text-align: center;
  line-height: 1.2;
}

@mixin goodbye-bold {
  line-height: 1.2;
  font-family: Austin;
  font-size: rem(60);
  @include rem-fluid-type((30 / 16) * 1rem, (60 / 16) * 1rem);
  text-align: center;
}
