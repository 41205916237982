.seleccion-sala {
  &__button {
    @include counter;
    color: $color__white;
    width: flex-vwidth(90);
    height: flex-vwidth(90);
    max-width: 104px;
    max-height: 104px;
    background-color: $color__grey;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    &--disabled {
      opacity: 0.5;
    }
  }
  &__item {
    margin: 0 flex-height(22.5) flex-height(25);
  }
  &__options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 750px;
    margin: 0 auto;
  }
}
